import {
  type ApiHelper,
  ApiHelperWithData,
  type BaseRequest,
  splitParams,
} from '@ocodelib/api-common';
import { type MOJob } from '../model/index';

/**
 * job api
 */
export class JobApi {
  private withData: ApiHelperWithData;
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  jobList = (
    params: BaseRequest,
  ): Promise<{
    jobList: MOJob[];
  }> => {
    const url = '/admin/job/list';
    return this.withData.post(url, ...splitParams(params));
  };
}
