import {
  type ApiHelper,
  ApiHelperWithData,
  type BaseRequest,
  splitParams,
  type PagingRequest,
} from '@ocodelib/api-common';
import type { CodingImageKindKey } from '../enum-types/coding-image-enum';
import type { CodingImageForAdm, NextPageData, PagerData, UploadedFile } from '../model/index';

/**
 * CodingImage api
 */
export class CodingImageApi {
  private withData: ApiHelperWithData;
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  create = (
    params: {
      kind: CodingImageKindKey;
      imageName: string;
      cateId: string;
      isBg: boolean;
      width: number;
      height: number;
      sortNumber?: number;
      fileIds: string[];
    } & BaseRequest,
  ): Promise<{ codingImage: CodingImageForAdm }> => {
    const url = '/admin/coding-img/create';
    return this.withData.postJson(url, ...splitParams(params));
  };

  update = (
    params: {
      imageId: number;
      imageName: string;
      width: number;
      height: number;
      fileIds: string[];
    } & BaseRequest,
  ): Promise<{ codingImage: CodingImageForAdm }> => {
    const url = '/admin/coding-img/update';
    return this.withData.postJson(url, ...splitParams(params));
  };

  updateInfo = (
    params: {
      imageId: number;
      imageName: string;
      cateId: string;
    } & BaseRequest,
  ): Promise<{ codingImage: CodingImageForAdm }> => {
    const url = '/admin/coding-img/update-info';
    return this.withData.postJson(url, ...splitParams(params));
  };

  list = (
    params: {
      rowsPerPage: number;
      pageNumber: number;
      cateId: string;
    } & PagingRequest,
  ): Promise<{ pagerData: PagerData<CodingImageForAdm> }> => {
    const url = '/admin/coding-img/list';
    return this.withData.postJson(url, ...splitParams(params));
  };

  search = (
    params: {
      keyword?: string;
      cateId?: string;
      isBg: boolean;
      nextKey?: string;
    } & BaseRequest,
  ): Promise<{ pagerData: NextPageData<CodingImageForAdm> }> => {
    const url = '/admin/coding-img/search';
    return this.withData.postJson(url, ...splitParams(params));
  };

  info = async (
    params: {
      imageId: number;
    } & BaseRequest,
  ): Promise<{ codingImage: CodingImageForAdm }> => {
    const url = '/admin/coding-img/info';
    return this.withData.post(url, ...splitParams(params));
  };

  uploadTempFile = async (
    params: {
      file: Blob;
      fileName: string;
      thumbnail: Blob;
    } & BaseRequest,
  ): Promise<{ file: UploadedFile }> => {
    const url = '/admin/coding-img/upload';
    const { ctx, file, fileName, thumbnail } = params;
    const formData = new FormData();
    formData.append('file', file, fileName);
    formData.append('thumbnail', thumbnail, 'thumb.png');
    return this.withData.postMultipart(url, formData, { ctx });
  };

  delete = async (
    params: {
      imageId: number;
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/admin/coding-img/delete';
    await this.helper.post(url, ...splitParams(params));
  };

  cateMove = async (
    params: {
      imageIds: number[];
      cateId: string;
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/admin/coding-img/cate-move';
    return this.withData.postJson(url, ...splitParams(params));
  };

  sortInCate = async (
    params: {
      imageIds: number[];
      cateId: string;
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/admin/coding-img/sort-in-cate';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * 코딩 이미지의 파일 교체
   */
  replaceImage = (
    params: {
      fileId: string;
      file: File;
      thumbnail: Blob;
    } & BaseRequest,
  ): Promise<{ imageUrl: string }> => {
    const url = '/admin/coding-img/replace-image-content';
    const { ctx, file, thumbnail, fileId } = params;
    const formData = new FormData();
    formData.append('fileId', fileId);
    formData.append('file', file, file.name);
    formData.append('thumbnail', thumbnail, 'thumb.png');
    return this.withData.postMultipart(url, formData, {
      ctx,
    });
  };
}
