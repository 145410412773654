import {
  ApiHelperWithData,
  type BaseRequest,
  splitParams,
  type ApiHelper,
  type PagingRequest,
} from '@ocodelib/api-common';
import type { PagerData, UserWithdraw } from '../model/index';

/**
 * UserWithdraw Api
 */
export class UserWithdrawApi {
  private withData: ApiHelperWithData;
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  /**
   * 회원탈퇴 등록
   */
  withdraw = (params: { targetAccountId: number; reason: string } & BaseRequest): Promise<void> => {
    const url = '/admin/user-withdraw/exec';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * 탈퇴 회원 목록
   */
  list = (
    params: { userId?: string } & PagingRequest,
  ): Promise<{ pagerData: PagerData<UserWithdraw> }> => {
    const url = '/admin/user-withdraw/list-all';
    return this.withData.postJson(url, ...splitParams(params));
  };

  purge = async (
    params: (
      | {
          userId: string;
        }
      | { accountId: number }
    ) &
      BaseRequest,
  ) => {
    const url = '/admin/user/purge';
    await this.helper.post(url, ...splitParams(params));
  };
}
