import type { BbsUser } from '../model/bbs/BbsUser';
import type { UploadedFile } from '../model/UploadedFile';

/**
 * 지금 현재는 common만 지원
 */
export type InkKindKey = 'common' | 'band' | 'lecture' | 'dtb';
export const InkKind: Record<InkKindKey, string> = {
  common: '일반',
  band: '밴드',
  lecture: '기초학습',
  dtb: '교과서',
};

/**
 * 책 배포 상태
 */
export type BookPublStateKey = 'PEND' | 'OK' | 'HIDE' | 'RJT' | 'DEL';
export const BookPublState: Record<BookPublStateKey, string> = {
  PEND: '미배포', // 배포된적 없음
  OK: '정상',
  HIDE: '숨김 상태', // 본인이 감춘 경우
  RJT: '승인 거부', // 관리자가 게시물을 감춘 경우
  DEL: '삭제',
};

export type BookItemTypeKey = 'chapter' | 'doc';

export type BookFileKindKey = 'ATTACH' | 'THUMB';
export type DocFileKindKey = 'ATTACH' | 'CONTENT';

export interface BookPublSimple {
  bookId: number;

  /**
   * lastPublBookSn은 bookId의 배포된 원본의 bookSn을 의미합니다.
   * lastPublBookSn과 bookSn이 다르면, 현재 bookSn은 DRAFT 버전임을 의미합니다.
   */
  bookSn: number;
  lastPublBookSn?: number;

  /**
   * lastPublTimestamp은 bookId의 마지막 배포시간을 의미합니다.
   * publTimestamp는 배포된적 없다면 undefined 이고, 배포된 적이 있다면 lastPublTimestamp와 같은 값입니다.
   */
  publTimestamp?: number;
  lastPublTimestamp?: number;

  /**
   * 난이도
   * 0: 설정안함
   * 1: 쉬움
   * 5: 보통
   * 10: 어려움
   */
  difficultyLevel: number;

  /**
   * 썸네일 URL
   */
  thumbnailUrl?: string;

  /**
   * 작성자 ACCOUNT_ID
   */
  accountId: number;

  /**
   * 제목
   */
  title: string;

  /**
   * 고정 여부
   */
  pinned: boolean;

  /**
   * 슬러그
   */
  slug?: string;

  /**
   * 카테고리
   */
  cate?: string;

  /**
   * 최종 배포 상태
   */
  lastPublState: BookPublStateKey;

  /**
   * 정렬키
   */
  sortKey: string;

  /**
   * 변경일시
   */
  changeTimestamp: number;

  /**
   * 생성일시
   */
  createTimestamp: number;

  /**
   * 작성자
   */
  author?: BbsUser;

  /**
   * 첨부파일수
   */
  attachFileCount: number;

  /**
   * 문서 개수
   */
  docCount: number;

  /**
   * 챕터 개수
   */
  chapterCount: number;

  /**
   * dirty 일시
   */
  dirtyTimestamp?: number;
}

export interface BookPubl extends BookPublSimple {
  /**
   * 소개 내용
   */
  introContent?: string;

  /**
   * 학습 목표
   */
  learningGoal?: string;
}

export interface Book {
  /**
   * BOOK_ID
   */
  bookId: number;

  /**
   * 책 일련번호
   */
  bookSn: number;

  /**
   * 작성자 ACCOUNT_ID
   */
  accountId: number;

  /**
   * 책 작성자
   */
  author?: BbsUser;

  /**
   * 책 제목
   */
  title: string;

  /**
   * SLUG
   */
  slug?: string;

  /**
   * 소개 내용
   */
  introContent?: string;

  /**
   * 학습 목표
   */
  learningGoal?: string;

  /**
   * 난이도
   * 0: 설정안함
   * 1: 쉬움
   * 5: 보통
   * 10: 어려움
   */
  difficultyLevel: number;

  /**
   * 썸네일 URL
   */
  thumbnailUrl?: string;

  /**
   * 썸네일 파일ID
   */
  thumbnailFileId?: string;

  /**
   * 첨부파일 개수
   */
  attachFileCount: number;

  /**
   * 문서 개수
   */
  docCount: number;

  /**
   * 챕터 개수
   */
  chapterCount: number;

  /**
   * 배포일시
   */
  publTimestamp?: number;

  /**
   * 변경일시
   */
  changeTimestamp: number;

  /**
   * 생성일시
   */
  createTimestamp: number;

  /**
   * dirty 일시
   */
  dirtyTimestamp?: number;
}

export interface BookDetails {
  book: BookPubl;
  bookItems: BookItem[];
  attachFiles?: UploadedFile[];
}

export interface BookItem {
  itemId: number; // docSn or chapterSn
  itemType: BookItemTypeKey;
  title: string;
  sortNumber: number;
}

/**
 * 이건 DTB에서 사용하는 용도
 */
export interface BookItemDoc {
  docSn: number;
  title: string;
  sortNumber: number;
  bookId: number;
}

export interface Chapter {
  chapterSn: number;
  title: string;
  sortNumber: number;
}

export interface DocSimple {
  docSn: number;
  accountId: number;
  writerNickName?: string;
  title: string;
  slug?: string;
  sortNumber: number;
  attachFileCount: number;
  changeTimestamp: number;
  createTimestamp: number;
}

/**
 * interface Doc
 */
export interface Doc extends DocSimple {
  content: string;
  contentSource?: string;
}
