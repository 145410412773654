export type SchoolYearKey =
  | 'A01'
  | 'A02'
  | 'A03'
  | 'A04'
  | 'A05'
  | 'A06'
  | 'B01'
  | 'B02'
  | 'B03'
  | 'C01'
  | 'C02'
  | 'C03'
  | 'D01';

export const SchoolYear: Record<SchoolYearKey, string> = {
  A01: '초1',
  A02: '초2',
  A03: '초3',
  A04: '초4',
  A05: '초5',
  A06: '초6',
  B01: '중1',
  B02: '중2',
  B03: '중3',
  C01: '고1',
  C02: '고2',
  C03: '고3',
  D01: '대학',
};

// export type SchoolSubjectKey =
//   | 'MATH'
//   | 'PHYSICS'
//   | 'INFO'
//   | 'SCI'
//   | 'CHEMI'
//   | 'MUSIC'
//   | 'ART'
//   | 'PRACART';
// export const SchoolSubject: Record<SchoolSubjectKey, string> = {
//   MATH: '수학',
//   PHYSICS: '물리',
//   INFO: '정보',
//   SCI: '과학',
//   CHEMI: '화학',
//   MUSIC: '음악',
//   ART: '미술',
//   PRACART: '실과',
// };

export interface SchoolYearSubjectPair {
  yearKey: SchoolYearKey;
  subjectKey: string;
}
