import {
  type ApiHelper,
  ApiHelperWithData,
  type BaseRequest,
  splitParams,
} from '@ocodelib/api-common';
import type { AdminAccount } from '../model/adm/AdminAccount';

/**
 * 관리자 인증 API
 */
export class AuthApi {
  private withData: ApiHelperWithData;
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  /**
   * 로그인
   */
  signIn = async (
    params: {
      loginId: string;
      pw: string;
    } & BaseRequest,
  ): Promise<{ profile: AdminAccount; pwResetNeed: boolean }> => {
    const url = '/p/admin/authenticate';
    const header = { Authorization: '' };
    const { loginId, pw, ctx } = params;

    return this.withData.postJson(
      url,
      { loginId, pw },
      { ctx, header: Object.assign({}, header, params.header) },
    );
  };

  /**
   * 로그아웃
   */
  signOut = async (params: BaseRequest): Promise<void> => {
    const url = '/admin/logout';
    await this.helper.post(url, ...splitParams(params));
  };
}
