import {
  type ApiHelper,
  ApiHelperWithData,
  type BaseRequest,
  splitParams,
} from '@ocodelib/api-common';
import type {
  AdminLogKindKey,
  BooleanSearchTypeKey,
  MOAuditLogKindKey,
  MOLogLevelKey,
} from '../enum-types/index';
import type {
  AdminAccessLog,
  AdminPwFail,
  AppLog,
  AuditLog,
  MOJobLog,
  PagerData,
  UserAccessLog,
} from '../model/index';

/**
 * system log api
 */
export class SystemLogApi {
  private withData: ApiHelperWithData;
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  /**
   * App 로그 목록 조회 - 페이징
   */
  appLogList = (
    params: {
      pageNumber: number;
      rowsPerPage: number;
      logLevels?: MOLogLevelKey[];
      userName?: string;
      loginId?: string;
      title?: string;
      logTag?: string;
      logYmd?: string;
    } & BaseRequest,
  ): Promise<{
    pagerData: PagerData<AppLog>;
  }> => {
    const url = '/admin/system-log/app-log-list';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * Job 로그 목록 조회 - 페이징
   */
  jobLogList = (
    params: {
      pageNumber: number;
      rowsPerPage: number;
      jobId?: string;
      logYmd?: string;
      logLevel: MOLogLevelKey[];
    } & BaseRequest,
  ): Promise<{
    pagerData: PagerData<MOJobLog>;
  }> => {
    const url = '/admin/system-log/job-log-list';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * Audit 로그 목록 조회 - 페이징
   */
  auditLogList = (
    params: {
      pageNumber: number;
      rowsPerPage: number;
      logLevels?: MOLogLevelKey[];
      logKind?: MOAuditLogKindKey;
      title?: string;
      jobId?: string;
      pageId?: string;
      operatorName?: string;
      logTag?: string;
      clientIp?: string;
      logYmd?: string;
    } & BaseRequest,
  ): Promise<{
    pagerData: PagerData<AuditLog>;
  }> => {
    const url = '/admin/system-log/audit-log-list';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * 관리자 접속 이력 조회
   */
  adminAccessLogList = (
    params: {
      pageNumber: number;
      rowsPerPage: number;
      kinds: AdminLogKindKey[];
      resultType: BooleanSearchTypeKey;
      workName?: string;
      loginId?: string;
      email?: string;
      clientIp?: string;
    } & BaseRequest,
  ): Promise<{ pagerData: PagerData<AdminAccessLog> }> => {
    const url = '/admin/system-log/admin-access-log-list';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * 비밀번호 실패 이력 조회
   */
  adminPwFailList = (
    params: {
      pageNumber: number;
      rowsPerPage: number;
      email?: string;
      ip?: string;
    } & BaseRequest,
  ): Promise<{ pagerData: PagerData<AdminPwFail> }> => {
    const url = '/admin/system-log/admin-pw-fail-list';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * 사용자 접속 이력 조회
   */
  userAccessLogList = (
    params: {
      pageNumber: number;
      rowsPerPage: number;
      kinds: AdminLogKindKey[];
      resultType: BooleanSearchTypeKey;
      workName?: string;
      userId?: string;
      clientIp?: string;
    } & BaseRequest,
  ): Promise<{ pagerData: PagerData<UserAccessLog> }> => {
    const url = '/admin/system-log/user-access-log-list';
    return this.withData.postJson(url, ...splitParams(params));
  };
}
