import {
  type ApiHelper,
  ApiHelperWithData,
  type BaseRequest,
  splitParams,
} from '@ocodelib/api-common';
import type { CodingSoundCateForAdm, CodingSoundZone } from '../model/index';

export class CodingSoundZoneApi {
  private withData: ApiHelperWithData;
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  list = (params: BaseRequest): Promise<{ zoneList: CodingSoundZone[] }> => {
    const url = '/admin/coding-sound-zone/list';
    return this.withData.postJson(url, ...splitParams(params));
  };

  info = (
    params: {
      zoneId: string;
    } & BaseRequest,
  ): Promise<{ zone: CodingSoundZone }> => {
    const url = '/admin/coding-sound-zone/info';
    return this.withData.post(url, ...splitParams(params));
  };

  create = (
    params: {
      zoneId: string;
      zoneName: string;
      disabled: boolean;
    } & BaseRequest,
  ): Promise<{ zone: CodingSoundZone }> => {
    const url = '/admin/coding-sound-zone/create';
    return this.withData.postJson(url, ...splitParams(params));
  };

  update = (
    params: {
      zoneId: string;
      zoneName: string;
      disabled: boolean;
    } & BaseRequest,
  ): Promise<{ zone: CodingSoundZone }> => {
    const url = '/admin/coding-sound-zone/update';
    return this.withData.postJson(url, ...splitParams(params));
  };

  delete = async (
    params: {
      zoneId: string;
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/admin/coding-sound-zone/delete';
    await this.helper.post(url, ...splitParams(params));
  };

  cateSort = async (
    params: {
      zoneId: string;
      cateIds: string[];
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/admin/coding-sound-zone/cate-sort';
    await this.helper.postJson(url, ...splitParams(params));
  };

  cateList = (
    params: {
      zoneId: string;
    } & BaseRequest,
  ): Promise<{ cateList: CodingSoundCateForAdm[] }> => {
    const url = '/admin/coding-sound-zone/cate-list';
    return this.withData.postJson(url, ...splitParams(params));
  };

  cateListReset = async (
    params: {
      zoneId: string;
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/admin/coding-sound-zone/cate-list-reset';
    await this.helper.post(url, ...splitParams(params));
  };
}
