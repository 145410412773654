import {
  type ApiHelper,
  ApiHelperWithData,
  type BaseRequest,
  splitParams,
} from '@ocodelib/api-common';
import type { IInkApi } from '../ink/IInkApi';
import type {
  Book,
  BookItem,
  BookPubl,
  BookPublSimple,
  Chapter,
  Doc,
  DocSimple,
  InkKindKey,
} from '../ink/ink-types';
import type { PagerData, UploadedFile } from '../model/index';

/**
 * book api
 */
export class InkApi implements IInkApi {
  private withData: ApiHelperWithData;
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  /**
   * BOOK 신규등록
   * 빈 책을 생성한다.
   */
  bookCreateEmpty = (
    params: {
      kind: InkKindKey;
    } & BaseRequest,
  ): Promise<{ book: BookPubl; bookItems: BookItem[]; attachFiles?: UploadedFile[] }> => {
    const { kind, ...restParams } = params;
    const url = `/admin/ink/${kind}/book-create-empty`;
    return this.withData.postJson(url, ...splitParams(restParams));
  };

  /**
   * BOOK 드래프트 조회 by bookId
   * 드래프트가 없는 경우 복제 생성되어 신규 bookSn을 응답한다.
   */
  bookGetDraftBookSnByBookId = (
    params: {
      kind: InkKindKey;
      bookId: number;
    } & BaseRequest,
  ): Promise<{ bookSn: number }> => {
    const { kind, ...restParams } = params;
    const url = `/admin/ink/${kind}/book-get-or-create-draft`;
    return this.withData.post(url, ...splitParams(restParams));
  };

  /**
   * BOOK 드래프트 조회 by bookSn
   * 드래프트가 없는 경우 복제 생성되어 신규 bookSn을 응답한다.
   * 드래프트를 조회하는 것은 원래 bookId로 하는 것이 논리적이지만
   * bookId를 모르고, bookSn만 알때 이 API를 호출할 수 있다.
   * bookSn으로 bookId를 찾아서 드래프트를 체크한다.
   */
  bookGetDraftBookSnByBookSn = (
    params: {
      kind: InkKindKey;
      bookSn: number;
    } & BaseRequest,
  ): Promise<{ bookSn: number }> => {
    const { kind, ...restParams } = params;
    const url = `/admin/ink/${kind}/book-get-or-create-draft-by-booksn`;
    return this.withData.post(url, ...splitParams(restParams));
  };

  /**
   * BOOK 제목 업데이트
   */
  bookUpdateTitle = (
    params: {
      kind: InkKindKey;

      /**
       * 책일련번호
       */
      bookSn: number;

      /**
       * 책 제목
       */
      title: string;
    } & BaseRequest,
  ): Promise<{ book: Book }> => {
    const { kind, ...restParams } = params;
    const url = `/admin/ink/${kind}/book-update-title`;
    return this.withData.postJson(url, ...splitParams(restParams));
  };

  /**
   * BOOK 업데이트
   */
  bookUpdate = (
    params: {
      kind: InkKindKey;

      /**
       * 책일련번호
       */
      bookSn: number;

      /**
       * 책 제목
       */
      title: string;

      /**
       * 소개 내용
       */
      introContent?: string;

      /**
       * 학습 목표
       */
      learningGoal?: string;

      /**
       * 난이도
       * 1: 쉬움
       * 5: 보통
       * 10: 어려움
       */
      difficultyLevel: number;

      /**
       * 썸네일 파일ID
       */
      thumbnailFileId?: string;

      /**
       * 첨부파일ID들
       */
      attachFileIds?: string[];
    } & BaseRequest,
  ): Promise<{ book: Book }> => {
    const { kind, ...restParams } = params;
    const url = `/admin/ink/${kind}/book-update`;
    return this.withData.postJson(url, ...splitParams(restParams));
  };

  /**
   * BOOK 상세 정보 조회
   */
  bookDetails = (
    params: {
      kind: InkKindKey;

      /**
       * 책일련번호
       */
      bookSn: number;
    } & BaseRequest,
  ): Promise<{
    book: BookPubl;
    bookItems: BookItem[];
    attachFiles?: UploadedFile[];
  }> => {
    const { kind, ...restParams } = params;
    const url = `/admin/ink/${kind}/book-details`;
    return this.withData.post(url, ...splitParams(restParams));
  };

  /**
   * BOOK의 드래프트 정보 조회
   * 편집할 때 로드
   */
  bookDraftDetails = (
    params: {
      kind: InkKindKey;

      /**
       * 책일련번호
       */
      bookSn: number;

      /**
       * BookItem 목록 제외 여부
       */
      excludeBookItems: boolean;
    } & BaseRequest,
  ): Promise<{
    book: Book;
    bookItems?: BookItem[];
    attachFiles?: UploadedFile[];
  }> => {
    const { kind, ...restParams } = params;
    const url = `/admin/ink/${kind}/book-draft-details`;
    return this.withData.post(url, ...splitParams(restParams));
  };

  /**
   * BOOK의 정보 조회
   * 편집할 때 로드
   */
  bookPublInfo = (
    params: {
      kind: InkKindKey;

      /**
       * 책ID
       */
      bookId: number;
    } & BaseRequest,
  ): Promise<{
    book: Book;
  }> => {
    const { kind, ...restParams } = params;
    const url = `/admin/ink/${kind}/book-publ-info`;
    return this.withData.post(url, ...splitParams(restParams));
  };

  /**
   * BOOK 슬러그 업데이트 배포
   */
  bookUpdateSlug = (
    params: {
      kind: InkKindKey;
      bookId: number;
      slug?: string;
    } & BaseRequest,
  ): Promise<{ book: Book }> => {
    const { kind, ...restParams } = params;
    const url = `/admin/ink/${kind}/book-update-slug`;
    return this.withData.postJson(url, ...splitParams(restParams));
  };

  /**
   * BOOK 배포
   */
  bookDeploy = async (
    params: {
      kind: InkKindKey;
      bookSn: number;
      slug?: string;
    } & BaseRequest,
  ): Promise<void> => {
    const { kind, ...restParams } = params;
    const url = `/admin/ink/${kind}/book-deploy`;
    await this.helper.postJson(url, ...splitParams(restParams));
  };

  /**
   * BOOK 드래프트 삭제 - by bookSn
   */
  bookDeleteDraft = async (
    params: {
      kind: InkKindKey;
      bookSn: number;
    } & BaseRequest,
  ): Promise<void> => {
    const { kind, ...restParams } = params;
    const url = `/admin/ink/${kind}/book-delete`;
    await this.helper.post(url, ...splitParams(restParams));
  };

  /**
   * BOOK 완전히 삭제 - by bookId
   * 모든 draft도 함께 삭제 된다.
   */
  bookPurge = async (
    params: {
      kind: InkKindKey;
      bookId: number;
    } & BaseRequest,
  ): Promise<void> => {
    const { kind, ...restParams } = params;
    const url = `/admin/ink/${kind}/book-purge`;
    await this.helper.post(url, ...splitParams(restParams));
  };

  /**
   * BOOK 검색
   */
  bookSearch = (
    params: {
      kind: InkKindKey;
      pageNumber: number;
      rowsPerPage: number;
      queryByAccountId?: number;
      listFilterType: 'BOTH' | 'ONLY_PUBLISHED' | 'ONLY_DRAFT';
      bookId?: number;
      bookSn?: number;
      title?: string;
      nickName?: string;
      cate?: string;
      bandId?: string;
    } & BaseRequest,
  ): Promise<{ pagerData: PagerData<BookPublSimple> }> => {
    const { kind, ...restParams } = params;
    const url = `/admin/ink/${kind}/book-search`;
    return this.withData.postJson(url, ...splitParams(restParams));
  };

  /**
   * CHAPTER 신규등록
   */
  chapterCreate = (
    params: {
      kind: InkKindKey;

      /**
       * 책일련번호
       */
      bookSn: number;

      /**
       * 챕터 제목
       */
      title: string;

      /**
       * 정렬 번호
       */
      sortNumber: number;
    } & BaseRequest,
  ): Promise<{ chapter: Chapter }> => {
    const { kind, ...restParams } = params;
    const url = `/admin/ink/${kind}/chapter-create`;
    return this.withData.postJson(url, ...splitParams(restParams));
  };

  /**
   * CHAPTER 여러건 등록
   */
  chapterCreateMulti = (
    params: {
      kind: InkKindKey;

      /**
       * 책일련번호
       */
      bookSn: number;

      /**
       * 챕터 제목들
       */
      titles: string[];

      sortNumber: number;
    } & BaseRequest,
  ): Promise<{ chapterList: Chapter[] }> => {
    const { kind, ...restParams } = params;
    const url = `/admin/ink/${kind}/chapter-create-multi`;
    return this.withData.postJson(url, ...splitParams(restParams));
  };

  /**
   * CHAPTER 내용 업데이트
   * 서버에서 새로운 chapterSn을 응답할 수 있습니다.
   *
   * 서버는 copyOnWrite가 설정된 CHAPTER의 경우 새로운 chapterSn을 응답합니다.
   * 요청한 것과 다른 chapterSn이므로 호출자는 chapterSn을 업데이트 해야 합니다.
   */
  chapterUpdateContent = (
    params: {
      kind: InkKindKey;

      /**
       * 책일련번호
       */
      bookSn: number;

      /**
       * 챕터일련번호
       */
      chapterSn: number;

      /**
       * 챕터 제목
       */
      title: string;
    } & BaseRequest,
  ): Promise<{ chapter: Chapter }> => {
    const { kind, ...restParams } = params;
    const url = `/admin/ink/${kind}/chapter-update-content`;
    return this.withData.postJson(url, ...splitParams(restParams));
  };

  /**
   * CHAPTER 삭제
   */
  chapterDelete = async (
    params: {
      kind: InkKindKey;

      /**
       * 책일련번호
       */
      bookSn: number;

      /**
       * 챕터일련번호
       */
      chapterSn: number;
    } & BaseRequest,
  ): Promise<void> => {
    const { kind, ...restParams } = params;
    const url = `/admin/ink/${kind}/chapter-delete`;
    await this.helper.post(url, ...splitParams(restParams));
  };

  /**
   * DOC 신규등록
   */
  docCreate = (
    params: {
      kind: InkKindKey;
      bookSn: number;
      title: string;
      content: string;
      contentSource?: string;
      sortNumber: number;
      cate?: string;
      attachFileIds?: string[];
    } & BaseRequest,
  ): Promise<{ doc: Doc }> => {
    const { kind, ...restParams } = params;
    const url = `/admin/ink/${kind}/doc-create`;
    return this.withData.postJson(url, ...splitParams(restParams));
  };

  /**
   * DOC 업데이트
   * 서버에서 새로운 docSn을 응답할 수 있습니다.
   *
   * 서버는 copyOnWrite가 설정된 DOC의 경우 새로운 docSn을 응답합니다.
   * 요청한 것과 다른 docSn이므로 호출자는 docSn을 업데이트 해야 합니다.
   */
  docUpdateContent = (
    params: {
      kind: InkKindKey;
      bookSn: number;
      docSn: number;
      title: string;
      content: string;
      contentSource?: string;
      cate?: string;
      attachFileIds?: string[];
    } & BaseRequest,
  ): Promise<{ doc: Doc; attachFiles?: UploadedFile[] }> => {
    const { kind, ...rest } = params;
    const url = `/admin/ink/${kind}/doc-update-content`;
    return this.withData.postJson(url, ...splitParams(rest));
  };

  /**
   * DOC 제목 업데이트
   * 서버에서 새로운 docSn을 응답할 수 있습니다.
   *
   * 서버는 copyOnWrite가 설정된 DOC의 경우 새로운 docSn을 응답합니다.
   * 요청한 것과 다른 docSn이므로 호출자는 docSn을 업데이트 해야 합니다.
   */
  docUpdateTitle = (
    params: {
      kind: InkKindKey;
      bookSn: number;
      docSn: number;
      title: string;
    } & BaseRequest,
  ): Promise<{ doc: Doc }> => {
    const { kind, ...rest } = params;
    const url = `/admin/ink/${kind}/doc-update-title`;
    return this.withData.postJson(url, ...splitParams(rest));
  };

  /**
   * DOC 정보
   */
  docInfo = (
    params: {
      kind: InkKindKey;
      docSn: number;
    } & BaseRequest,
  ): Promise<{ doc: Doc; attachFiles?: UploadedFile[] }> => {
    const { kind, ...restParams } = params;
    const url = `/admin/ink/${kind}/doc-info`;
    return this.withData.post(url, ...splitParams(restParams));
  };

  /**
   * 사용할일이 없다
   */
  docList = (
    params: {
      kind: InkKindKey;
      bookSn: number;
      title?: string | null;
    } & BaseRequest,
  ): Promise<{ docList: DocSimple[] }> => {
    const { kind, ...restParams } = params;
    const url = `/admin/ink/${kind}/doc-list`;
    return this.withData.postJson(url, ...splitParams(restParams));
  };

  /**
   * DOC 삭제
   */
  docDelete = async (
    params: {
      kind: InkKindKey;
      bookSn: number;
      docSn: number;
    } & BaseRequest,
  ): Promise<void> => {
    const { kind, ...restParams } = params;
    const url = `/admin/ink/${kind}/doc-delete`;
    await this.helper.post(url, ...splitParams(restParams));
  };

  /**
   * BOOK_ITEM 정렬
   */
  bookItemSort = (
    params: {
      kind: InkKindKey;

      /**
       * 책일련번호
       */
      bookSn: number;

      /**
       * docSn or chapterSn의 배열
       */
      itemIds: number[];
    } & BaseRequest,
  ): Promise<void> => {
    const { kind, ...restParams } = params;
    const url = `/admin/ink/${kind}/book-item-sort`;
    return this.withData.postJson(url, ...splitParams(restParams));
  };

  /**
   * DOC_FILE 업로드
   */
  uploadDocFileTemp = (
    params: {
      kind: InkKindKey;
      file: Blob;
      fileName: string;
      fileKind: 'ATTACH' | 'CONTENT';
    } & BaseRequest,
  ): Promise<{ file: UploadedFile }> => {
    const { ctx, kind, file, fileName, fileKind } = params;
    const url = `/admin/ink/${kind}/doc-file-upload`;
    const formData = new FormData();
    formData.append('file', file, fileName);
    formData.append('fileKind', fileKind);
    return this.withData.postMultipart(url, formData, { ctx });
  };

  /**
   * BOOK_FILE 업로드
   */
  uploadBookFileTemp = (
    params: {
      kind: InkKindKey;
      file: Blob;
      fileName: string;
      fileKind: 'ATTACH' | 'THUMB';
    } & BaseRequest,
  ): Promise<{ file: UploadedFile }> => {
    const { ctx, kind, file, fileName, fileKind } = params;
    const url = `/admin/ink/${kind}/book-file-upload`;
    const formData = new FormData();
    formData.append('file', file, fileName);
    formData.append('fileKind', fileKind);
    return this.withData.postMultipart(url, formData, { ctx });
  };
}
