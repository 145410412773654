import {
  type ApiHelper,
  ApiHelperWithData,
  type BaseRequest,
  splitParams,
} from '@ocodelib/api-common';
import { type SchoolYearKey, type SchoolYearSubjectPair } from '../enum-types';
import { type BookItemDoc } from '../ink/ink-types';
import { type DtbItem, type DtbPubl } from '../model/dtb/index';

/**
 * dtb api
 */
export class DtbApi {
  private withData: ApiHelperWithData;
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  /**
   * yearKey 다건 조회 by subjectKey
   */
  getYearKeysBySubjectKey = (
    params: {
      subjectKey: string;
    } & BaseRequest,
  ): Promise<{
    yearKeys: SchoolYearKey[];
  }> => {
    const url = '/p/api/dtb/publ-year-keys-by-subject';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * subjectKey 다건 조회 by yearKey
   */
  getSubjectKeysByYearKey = (
    params: {
      yearKey: SchoolYearKey;
    } & BaseRequest,
  ): Promise<{
    subjectKeys: string[];
  }> => {
    const url = '/p/api/dtb/publ-subject-keys-by-year';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * yearKey 다건 조회 by all subjects
   */
  getYearKeys = (
    params: BaseRequest,
  ): Promise<{
    yearKeys: SchoolYearKey[];
  }> => {
    const url = '/p/api/dtb/publ-year-keys';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * subjectKey 다건 조회 by all years
   */
  getSubjectKeys = (
    params: BaseRequest,
  ): Promise<{
    subjectKeys: string[];
  }> => {
    const url = '/p/api/dtb/publ-subject-keys';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * YearAndSubjectPair 다건 조회
   */
  getYearAndSubjectKeyPairs = (
    params: BaseRequest,
  ): Promise<{
    yearAndSubjects: SchoolYearSubjectPair[];
  }> => {
    const url = '/p/api/dtb/publ-year-and-subject-pairs';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * DTB 상세 정보 조회
   * 배포된 것
   */
  publishedDtbDetails = (
    params: {
      yearKey: SchoolYearKey;
      subjectKey: string;
    } & BaseRequest,
  ): Promise<{
    dtb: DtbPubl;
    dtbItems: DtbItem[];
  }> => {
    const url = '/p/api/dtb/published-dtb-details';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * DTB 상세 정보 조회
   * 배포된 것
   */
  publishedDtbDetailsByDtbId = (
    params: {
      dtbId: number;
    } & BaseRequest,
  ): Promise<{
    dtb: DtbPubl;
    dtbItems: DtbItem[];
  }> => {
    const url = '/p/api/dtb/published-dtb-details-by-dtbid';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * DTB 상세 정보 조회
   * 배포된 것
   */
  publishedDtbDocsByDtbId = (
    params: {
      dtbId: number;
    } & BaseRequest,
  ): Promise<{
    dtb: DtbPubl;
    dtbItems: DtbItem[];
    docItems: BookItemDoc[];
  }> => {
    const url = '/p/api/dtb/published-dtb-docs-by-dtbid';
    return this.withData.post(url, ...splitParams(params));
  };
}
