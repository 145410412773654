import {
  ApiHelperWithData,
  type BaseRequest,
  splitParams,
  type ApiHelper,
} from '@ocodelib/api-common';
import type { WebProp, WebPropSimple } from '../model/index';

export class WebPropApi {
  private withData: ApiHelperWithData;

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  /**
   * WebProp 신규 등록
   */
  create = (
    params: {
      propKey: string;
      value: string;
      lang: string; // 'text' | 'json' | 'javascript' | 'xml'
      memo?: string;
    } & BaseRequest,
  ): Promise<{ webProp: WebProp }> => {
    const url = '/admin/web-prop/create';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * WebProp 업데이트
   */
  update = (
    params: {
      propId: number;
      propKey: string;
      value: string;
      lang: string; // 'text' | 'json' | 'javascript' | 'xml'
      memo: string;
    } & BaseRequest,
  ): Promise<{ webProp: WebProp }> => {
    const url = '/admin/web-prop/update';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * WebProp 전체 목록 조회
   */
  listAll = (params: BaseRequest): Promise<{ webPropList: WebProp[] }> => {
    const url = '/admin/web-prop/list-all';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * WebProp 검색
   */
  search = (
    params: {
      propKey?: string;
      value?: string;
    } & BaseRequest,
  ): Promise<{ webProp: WebProp }> => {
    const url = '/admin/web-prop/search';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * WebProp 단건 조회 by propId
   */
  info = (
    params: {
      propId: number;
    } & BaseRequest,
  ): Promise<{ webProp: WebProp }> => {
    const url = '/admin/web-prop/info';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * WebProp 단건 조회 by propKey
   */
  find = (
    params: {
      propKey: string;
    } & BaseRequest,
  ): Promise<{ webProp: WebProp }> => {
    const url = '/admin/web-prop/find';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * WebProp 삭제
   */
  delete = async (
    params: {
      propKey: string;
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/admin/web-prop/delete';
    await this.helper.post(url, ...splitParams(params));
  };

  /**
   * WebProp 단건 조회 by propKey
   * TODO 사용자용 API를 관리자용으로 하나더 만들어야 하나?
   */
  findByPropKey = (
    params: {
      propKey: string;
    } & BaseRequest,
  ): Promise<{ webProp: WebPropSimple }> => {
    const url = '/p/api/web-prop/find';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * WebProp 단건 조회 by propKey
   * TODO 사용자용 API를 관리자용으로 하나더 만들어야 하나?
   */
  findByPropKeys = (
    params: {
      propKeys: string[];
    } & BaseRequest,
  ): Promise<{ webPropList: WebPropSimple[] }> => {
    const url = '/p/api/web-prop/find-by-prop-keys';
    const { propKeys, ...rest } = params;
    return this.withData.post(url, { propKeys: propKeys.join(',') }, rest);
  };
}
