import {
  ApiHelper,
  type ErrorResponseInterceptor,
  type ResponseInterceptor,
} from '@ocodelib/api-common';
import type { AxiosInstance } from 'axios';
import { DatiZipApi, SysKeyApi } from './common-api/index';
import { errorToMessage } from './errors/index';
import {
  AuthApi,
  BbsApi,
  CoppiaApi,
  DtbApi,
  GalleryApi,
  InkApi,
  InkPublishedApi,
  P5ProjectApi,
  ProjectApi,
  PyProjectApi,
  UserDatiApi,
  UserProfileApi,
  WebPropApi,
} from './user-main-apis/index';
import { createLazyApiGenerator } from './util/create-lazy-api-generator';

export class UserMainApi {
  readonly helper: ApiHelper;

  #lazyNew: ReturnType<typeof createLazyApiGenerator>;

  constructor(options: {
    apiBaseURL: string;
    createApiHeader: () => Record<string, string>;
    responseInterceptor: ResponseInterceptor;
    errorResponseInterceptors: ((axiosInstance: AxiosInstance, error: any) => any)[];
    defaultErrorResponseInterceptor: ErrorResponseInterceptor;
    debug?: boolean;
  }) {
    //
    const {
      apiBaseURL,
      createApiHeader,
      responseInterceptor,
      errorResponseInterceptors,
      defaultErrorResponseInterceptor,
      debug,
    } = options;

    this.helper = new ApiHelper(apiBaseURL, {
      createApiHeader,
      responseInterceptor,
      errorResponseInterceptors,
      defaultErrorResponseInterceptor,
      errorToMessage,
      debug,
    });

    this.#lazyNew = createLazyApiGenerator(this);
  }

  get auth() {
    return this.#lazyNew(AuthApi);
  }

  get webProp() {
    return this.#lazyNew(WebPropApi);
  }

  get coppia() {
    return this.#lazyNew(CoppiaApi);
  }

  get userProfile() {
    return this.#lazyNew(UserProfileApi);
  }

  get userDati() {
    return this.#lazyNew(UserDatiApi);
  }

  /**
   * @deprecated ProjectApi
   */
  get p5Project() {
    return this.#lazyNew(P5ProjectApi);
  }

  /**
   * @deprecated ProjectApi
   */
  get pyProject() {
    return this.#lazyNew(PyProjectApi);
  }

  get project() {
    return this.#lazyNew(ProjectApi);
  }

  get gallery() {
    return this.#lazyNew(GalleryApi);
  }

  get bbs() {
    return this.#lazyNew(BbsApi);
  }

  get ink() {
    return this.#lazyNew(InkApi);
  }

  get inkPublished() {
    return this.#lazyNew(InkPublishedApi);
  }

  get datiZip() {
    return this.#lazyNew(DatiZipApi);
  }

  get dtb() {
    return this.#lazyNew(DtbApi);
  }

  get sysKey() {
    return this.#lazyNew(SysKeyApi);
  }
}
