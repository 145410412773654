import {
  ApiHelperWithData,
  type BaseRequest,
  splitParams,
  type ApiHelper,
} from '@ocodelib/api-common';
import type { GalleryKeywordFieldKey, GalleryStampKindKey, ProjectBreedKey } from '../enum-types';
import type {
  Gallery,
  GalleryReply,
  GallerySimple,
  NextPageData,
  PagerData,
  UploadedFile,
} from '../model/index';

export class GalleryApi {
  private withData: ApiHelperWithData;

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  /**
   * 갤러리 등록
   */
  create = (
    params: {
      projectId: string;
      title: string;
      introContent?: string;
      youtubeUrl?: string;
      hashtag?: string;
      content?: string;
    } & BaseRequest,
  ): Promise<{ gallery: Gallery }> => {
    const url = '/api/gallery/create';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * 갤러리 수정
   */
  update = (
    params: {
      galleryId: number;
      title: string;
      introContent?: string;
      youtubeUrl?: string;
      hashtag?: string;
      content?: string;
    } & BaseRequest,
  ): Promise<{ gallery: Gallery }> => {
    const url = '/api/gallery/update';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * 갤러리의 본문에서 data url 부분을 업로드
   */
  uploadGalleryFile = async (
    params: {
      file: Blob;
      fileName: string;
    } & BaseRequest,
  ): Promise<{ file: UploadedFile }> => {
    const url = '/api/gallery/upload-file';
    const { ctx, file, fileName } = params;
    const formData = new FormData();
    formData.append('file', file, fileName);
    return this.withData.postMultipart(url, formData, { ctx });
  };

  /**
   * 갤러리 정보 조회
   */
  info = (
    params: {
      galleryId: number;
    } & BaseRequest,
  ): Promise<{ gallery: Gallery }> => {
    const url = '/p/api/gallery/info';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * 프로젝트가 갤러리에 등록된 프로젝트인지 여부 조회
   */
  existsGalleryByProjectId = async (
    params: {
      galleryId: number;
      bandId?: number;
    } & BaseRequest,
  ): Promise<boolean> => {
    const url = '/p/api/gallery/check-project-registered';
    const result: { value: boolean } = await this.withData.post(url, ...splitParams(params));
    return result.value;
  };

  /**
   * 익명 갤러리 목록 조회
   */
  search = (
    params: {
      pageNumber: number;
      rowsPerPage: number;

      /**
       * 프로젝트 종류, 빈배열인 경우 전체 조회
       */
      breed: ProjectBreedKey[];

      /**
       * 검색 키워드의 필드
       */
      keywordField?: GalleryKeywordFieldKey;

      /**
       * 최대 30글자
       */
      keyword?: string;

      /**
       * 일반,우수,인기 구분
       * 값을 전달하지 않으면 모두 조회
       */
      stampKind?: GalleryStampKindKey;

      /**
       * 특정 사용자의 갤러리만 조회
       */
      accountId?: number;

      /**
       * 어떻게 할지 고민중
       * 일단은 값을 전달하지 않는다
       */
      bandId?: number;
    } & BaseRequest,
  ): Promise<{ pagerData: PagerData<GallerySimple> }> => {
    const url = '/p/api/gallery/search';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * 본인 소유의 갤러리 목록 조회
   */
  listMine = (
    params: {
      pageNumber: number;
      rowsPerPage: number;

      /**
       * 프로젝트 종류, 빈배열인 경우 전체 조회
       */
      breed: ProjectBreedKey[];

      /**
       * 검색 키워드의 필드
       */
      keywordField?: GalleryKeywordFieldKey;

      /**
       * 최대 30글자
       */
      keyword?: string;

      /**
       * 일반,우수,인기 구분
       * 값을 전달하지 않으면 모두 조회
       */
      stampKind?: GalleryStampKindKey;

      /**
       * 어떻게 할지 고민중
       * 일단은 값을 전달하지 않는다
       */
      bandId?: number;
    } & BaseRequest,
  ): Promise<{ pagerData: PagerData<GallerySimple> }> => {
    const url = '/api/gallery/list-mine';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * 본인이 찜한 갤러리 목록 조회
   */
  listChim = (
    params: {
      pageNumber: number;
      rowsPerPage: number;

      /**
       * 프로젝트 종류, 빈배열인 경우 전체 조회
       */
      breed: ProjectBreedKey[];

      /**
       * 검색 키워드의 필드
       */
      keywordField?: GalleryKeywordFieldKey;

      /**
       * 최대 30글자
       */
      keyword?: string;

      /**
       * 일반,우수,인기 구분
       * 값을 전달하지 않으면 모두 조회
       */
      stampKind?: GalleryStampKindKey;

      /**
       * 어떻게 할지 고민중
       * 일단은 값을 전달하지 않는다
       */
      bandId?: number;
    } & BaseRequest,
  ): Promise<{ pagerData: PagerData<GallerySimple> }> => {
    const url = '/api/gallery/list-chim';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * 본인이 좋아요한 갤러리 목록 조회
   * 사용안함: 좋아요한 작품을 조회하는 기능은 없음. 서버 API는 있지만 사용안함
   */
  listFavor = (
    params: {
      pageNumber: number;
      rowsPerPage: number;

      /**
       * 프로젝트 종류, 빈배열인 경우 전체 조회
       */
      breed: ProjectBreedKey[];

      /**
       * 검색 키워드의 필드
       */
      keywordField?: GalleryKeywordFieldKey;

      /**
       * 최대 30글자
       */
      keyword?: string;

      /**
       * 일반,우수,인기 구분
       * 값을 전달하지 않으면 모두 조회
       */
      stampKind?: GalleryStampKindKey;

      /**
       * 어떻게 할지 고민중
       * 일단은 값을 전달하지 않는다
       */
      bandId?: number;
    } & BaseRequest,
  ): Promise<{ pagerData: PagerData<GallerySimple> }> => {
    const url = '/api/gallery/list-favor';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * 갤러리 좋아요 추가
   * 좋아요 건수 리턴
   */
  favorAdd = (
    params: {
      galleryId: number;
    } & BaseRequest,
  ): Promise<{ value: number }> => {
    const url = '/api/gallery/favor-add';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * 갤러리 좋아요 제거
   * 좋아요 건수 리턴
   */
  favorRemove = (
    params: {
      galleryId: number;
    } & BaseRequest,
  ): Promise<{ value: number }> => {
    const url = '/api/gallery/favor-remove';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * 갤러리 찜 추가
   * 찜 건수 리턴
   */
  chimAdd = (
    params: {
      galleryId: number;
    } & BaseRequest,
  ): Promise<{ value: number }> => {
    const url = '/api/gallery/chim-add';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * 갤러리 찜 제거
   * 찜 건수 리턴
   */
  chimRemove = (
    params: {
      galleryId: number;
    } & BaseRequest,
  ): Promise<{ value: number }> => {
    const url = '/api/gallery/chim-remove';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * 갤러리 삭제
   */
  delete = async (
    params: {
      galleryId: number;
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/api/gallery/delete';
    await this.helper.post(url, ...splitParams(params));
  };

  /**
   * 갤러리 댓글 등록
   */
  replyCreate = (
    params: {
      galleryId: number;
      content: string;
    } & BaseRequest,
  ): Promise<{ reply: GalleryReply }> => {
    const url = '/api/gallery/reply-create';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * 갤러리 댓글 수정
   */
  replyUpdate = (
    params: {
      replyId: number;
      content: string;
    } & BaseRequest,
  ): Promise<{ reply: GalleryReply }> => {
    const url = '/api/gallery/reply-update';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * 갤러리 댓글의 본문에서 data url 부분을 업로드
   */
  uploadReplyFile = async (
    params: {
      file: Blob;
      fileName: string;
    } & BaseRequest,
  ): Promise<{ file: UploadedFile }> => {
    const url = '/api/gallery/reply-upload-file';
    const { ctx, file, fileName } = params;
    const formData = new FormData();
    formData.append('file', file, fileName);
    return this.withData.postMultipart(url, formData, { ctx });
  };

  /**
   * 갤러리 댓글 정보 조회
   */
  replyInfo = (
    params: {
      replyId: number;
    } & BaseRequest,
  ): Promise<{ reply: GalleryReply }> => {
    const url = '/p/api/gallery/reply-info';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * 갤러리 댓글 목록 조회 - nextKey 페이징
   */
  replyList = (
    params: {
      galleryId?: number;
      accountId?: number;
      nextKey?: string;
      maxCount: number;
    } & BaseRequest,
  ): Promise<{ pagerData: NextPageData<GalleryReply> }> => {
    const url = '/p/api/gallery/reply-list';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * 갤러리 댓글 삭제
   * 갤러리의 댓글 건수를 리턴합니다.
   */
  replyDelete = async (
    params: {
      replyId: number;
    } & BaseRequest,
  ): Promise<{ value: number }> => {
    const url = '/api/gallery/reply-remove';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * 갤러리 댓글 고정 추가
   */
  replyPinAdd = (
    params: {
      replyId: number;
    } & BaseRequest,
  ): Promise<{ reply: GalleryReply }> => {
    const url = '/api/gallery/reply-pin-add';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * 갤러리 댓글 고정 제거
   */
  replyPinRemove = (
    params: {
      replyId: number;
    } & BaseRequest,
  ): Promise<{ reply: GalleryReply }> => {
    const url = '/api/gallery/reply-pin-remove';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * 갤러리 댓글의 좋아요 추가
   * 댓글의 좋아요 건수 리턴
   */
  replyFavorAdd = (
    params: {
      replyId: number;
    } & BaseRequest,
  ): Promise<{ value: number }> => {
    const url = '/api/gallery/reply-favor-add';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * 갤러리 댓글의 좋아요 제거
   * 댓글의 좋아요 건수 리턴
   */
  replyFavorRemove = (
    params: {
      replyId: number;
    } & BaseRequest,
  ): Promise<{ value: number }> => {
    const url = '/api/gallery/reply-favor-remove';
    return this.withData.post(url, ...splitParams(params));
  };
}
