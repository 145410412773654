import {
  type ApiHelper,
  ApiHelperWithData,
  type BaseRequest,
  splitParams,
} from '@ocodelib/api-common';
import { type SchoolYearKey } from '../enum-types';
import { type DtbListFilterTypeKey } from '../enum-types/dtb-enum';
import { type Dtb, type DtbItem, type DtbPubl } from '../model/dtb/index';

/**
 * dtb api
 */
export class DtbApi {
  private withData: ApiHelperWithData;
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  dtbCreate = (
    params: {
      subjectKey: string;
      yearKeys: string[];
    } & BaseRequest,
  ): Promise<{
    dtb: DtbPubl;
    dtbItems: DtbItem[];
  }> => {
    const url = '/admin/dtb/dtb-create';
    return this.withData.post(url, ...splitParams(params));
  };

  dtbUpdateYearKeys = (
    params: {
      dtbId: number;
      yearKeys: string[];
    } & BaseRequest,
  ): Promise<{
    dtb: DtbPubl;
  }> => {
    const url = '/admin/dtb/dtb-update-year-keys';
    return this.withData.post(url, ...splitParams(params));
  };

  // dtbGetOrCreate = (
  //   params: {
  //     subjectKey: string;
  //     yearKey: string;
  //   } & BaseRequest,
  // ): Promise<{
  //   dtb: DtbPubl;
  //   dtbItems: DtbItem[];
  // }> => {
  //   const url = '/admin/dtb/dtb-get-or-create';
  //   return this.withData.post(url, ...splitParams(params));
  // };

  searchDtbs = (
    params: {
      dtbId?: number;
      dtbSn?: number;
      subjectKey?: string;
      yearKey?: string;
      listFilterType: DtbListFilterTypeKey;
    } & BaseRequest,
  ): Promise<{
    dtbList: DtbPubl[];
  }> => {
    const url = '/admin/dtb/dtb-search';
    return this.withData.postJson(url, ...splitParams(params));
  };

  dtbDeleteDraft = async (
    params: {
      dtbSn: number;
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/admin/dtb/dtb-delete-draft';
    await this.helper.post(url, ...splitParams(params));
  };

  dtbResetToLastPublish = async (
    params: {
      yearKey: string;
      subjectKey: string;
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/admin/dtb/reset-to-last-publish';
    await this.helper.post(url, ...splitParams(params));
  };

  dtbPurge = async (
    params: {
      dtbId: number;
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/admin/dtb/dtb-purge';
    await this.helper.post(url, ...splitParams(params));
  };

  /**
   * DTB_ITEM: SECTION 신규등록
   */
  sectionCreate = (
    params: {
      dtbSn: number;

      /**
       * 섹션 제목
       */
      title: string;

      /**
       * 정렬 번호
       */
      sortNumber: number;
    } & BaseRequest,
  ): Promise<{ item: DtbItem }> => {
    const url = '/admin/dtb/section-create';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * DTB_ITEM: SECTION 신규등록
   */
  sectionCreateMulti = (
    params: {
      dtbSn: number;

      /**
       * 섹션 제목
       */
      titles: string[];

      /**
       * 정렬 번호
       */
      sortNumber: number;
    } & BaseRequest,
  ): Promise<{ items: DtbItem[] }> => {
    const url = '/admin/dtb/section-create-multi';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * DTB_ITEM: SECTION 업데이트
   */
  sectionUpdate = (
    params: {
      dtbSn: number;

      sectionSn: number;

      /**
       * 섹션 제목
       */
      title: string;
    } & BaseRequest,
  ): Promise<{ item: DtbItem }> => {
    const url = '/admin/dtb/section-update';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * DTB_ITEM: SECTION 삭제
   */
  sectionDelete = async (
    params: {
      dtbSn: number;

      sectionSn: number;
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/admin/dtb/section-delete';
    await this.helper.postJson(url, ...splitParams(params));
  };

  /**
   * DTB_ITEM: BOOK 신규등록
   */
  bookCreate = (
    params: {
      dtbSn: number;

      /**
       * BOOK 제목
       */
      title: string;

      /**
       * 정렬 번호
       */
      sortNumber: number;
    } & BaseRequest,
  ): Promise<{ item: DtbItem }> => {
    const url = '/admin/dtb/book-create';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * DTB_ITEM: BOOK 신규등록
   * 여러건 등록
   */
  bookCreateMulti = (
    params: {
      dtbSn: number;

      /**
       * BOOK 제목
       */
      titles: string[];

      /**
       * 정렬 번호
       */
      sortNumber: number;
    } & BaseRequest,
  ): Promise<{ items: DtbItem[] }> => {
    const url = '/admin/dtb/book-create-multi';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * DTB_ITEM: BOOK 삭제
   */
  bookDelete = async (
    params: {
      dtbSn: number;

      bookId: number;
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/admin/dtb/book-delete';
    await this.helper.postJson(url, ...splitParams(params));
  };

  /**
   * DTB_ITEM 정렬
   */
  dtbItemSort = async (
    params: {
      dtbSn: number;

      /**
       * bookId or sectionSn의 배열
       */
      itemIds: number[];
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/admin/dtb/dtb-item-sort';
    await this.helper.postJson(url, ...splitParams(params));
  };

  /**
   * DTB 상세 정보 조회
   * 배포된 것
   */
  publishedDtbDetails = (
    params: {
      yearKey: SchoolYearKey;
      subjectKey: string;
    } & BaseRequest,
  ): Promise<{
    dtb: DtbPubl;
    dtbItems: DtbItem[];
  }> => {
    const url = '/admin/dtb/published-dtb-details';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * DTB 드래프트 등록
   * dtbId를 복제하여 DTB를 생성한다.
   */
  dtbGetOrCreateDraftDtbSn = (
    params: {
      dtbId: number;
    } & BaseRequest,
  ): Promise<{ dtbSn: number }> => {
    const url = '/admin/dtb/dtb-get-or-create-draft';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * BOOK의 드래프트 정보 조회
   * 편집할 때 로드
   */
  dtbDraftDetails = (
    params: {
      /**
       * DTB 일련번호
       */
      dtbSn: number;

      /**
       * DtbItem 목록 제외 여부
       */
      excludeDtbItems: boolean;
    } & BaseRequest,
  ): Promise<{
    dtb: Dtb;
    dtbItems?: DtbItem[];
  }> => {
    const url = '/admin/dtb/dtb-draft-details';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * DTB 배포
   * dtbId로 배포한다.
   */
  dtbDeploy = async (
    params: {
      dtbId: number;
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/admin/dtb/deploy';
    await this.helper.post(url, ...splitParams(params));
  };
}
