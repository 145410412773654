import {
  type ApiHelper,
  ApiHelperWithData,
  type BaseRequest,
  splitParams,
} from '@ocodelib/api-common';
import type { UserAccount, UserAccountSimple } from '../model/index';

/**
 * 사용자 프로필 Api
 */
export class UserProfileApi {
  private withData: ApiHelperWithData;
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  /**
   * 본인 사용자 프로필 조회
   */
  profileMe = (params?: BaseRequest): Promise<{ profile: UserAccount }> => {
    const url = '/api/user/profile-me';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * 다른 사용자 프로필 조회
   */
  profileOther = (
    params: { accountId: number } & BaseRequest,
  ): Promise<{ profile: UserAccountSimple }> => {
    const url = '/p/api/user/profile-other';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * 사용자 프로필 이미지 업로드
   */
  uploadProfileImage = (
    params: {
      file: Blob;
    } & BaseRequest,
  ): Promise<{ profile: UserAccount }> => {
    const url = '/api/user/upload-profile-image';
    const { ctx, file } = params;
    const formData = new FormData();
    formData.append('file', file, 'profile.png');
    return this.withData.postMultipart(url, formData, { ctx });
  };
}
