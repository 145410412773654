import {
  ApiHelper,
  type ErrorResponseInterceptor,
  type ResponseInterceptor,
} from '@ocodelib/api-common';
import {
  AdminProfileApi,
  AuthApi,
  BbsApi,
  BrowserStatApi,
  CodingImageApi,
  CodingImageCateApi,
  CodingImageZoneApi,
  CodingSoundApi,
  CodingSoundCateApi,
  CodingSoundZoneApi,
  CoppiaApi,
  DatiApi,
  DatiDomainApi,
  DtbApi,
  InkApi,
  JobApi,
  PdsApi,
  ServerApi,
  SysKeyManageApi,
  SystemLogApi,
  UserApi,
  UserKickApi,
  UserStatApi,
  UserWithdrawApi,
  WebPropApi,
} from './admin-main-apis/index';
// InkPublishedApi는 사용자 API와 공유
import type { AxiosInstance } from 'axios';
import { DatiZipApi, SysKeyApi } from './common-api/index';
import { errorToMessage } from './errors/index';
import { InkPublishedApi } from './user-main-apis/InkPublishedApi';
import { createLazyApiGenerator } from './util/create-lazy-api-generator';

export class AdminMainApi {
  readonly helper: ApiHelper;

  constructor(options: {
    apiBaseURL: string;
    createApiHeader: () => Record<string, string>;
    responseInterceptor: ResponseInterceptor;
    errorResponseInterceptors: ((axiosInstance: AxiosInstance, error: any) => any)[];
    defaultErrorResponseInterceptor: ErrorResponseInterceptor;
    debug?: boolean;
  }) {
    //
    const {
      apiBaseURL,
      createApiHeader,
      responseInterceptor,
      errorResponseInterceptors,
      defaultErrorResponseInterceptor,
      debug,
    } = options;

    this.helper = new ApiHelper(apiBaseURL, {
      createApiHeader,
      responseInterceptor,
      errorResponseInterceptors,
      defaultErrorResponseInterceptor,
      errorToMessage,
      debug,
    });

    this.#lazyNew = createLazyApiGenerator(this);
  }

  #lazyNew: ReturnType<typeof createLazyApiGenerator>;

  get dati() {
    return this.#lazyNew(DatiApi);
  }

  get datiDomain() {
    return this.#lazyNew(DatiDomainApi);
  }

  get datiZip() {
    return this.#lazyNew(DatiZipApi);
  }

  get auth() {
    return this.#lazyNew(AuthApi);
  }

  get adminProfile() {
    return this.#lazyNew(AdminProfileApi);
  }

  get coppia() {
    return this.#lazyNew(CoppiaApi);
  }

  get webProp() {
    return this.#lazyNew(WebPropApi);
  }

  get systemLog() {
    return this.#lazyNew(SystemLogApi);
  }

  get job() {
    return this.#lazyNew(JobApi);
  }

  get userStat() {
    return this.#lazyNew(UserStatApi);
  }

  get browserStat() {
    return this.#lazyNew(BrowserStatApi);
  }

  get userKick() {
    return this.#lazyNew(UserKickApi);
  }

  get server() {
    return this.#lazyNew(ServerApi);
  }

  get user() {
    return this.#lazyNew(UserApi);
  }

  get userWithdraw() {
    return this.#lazyNew(UserWithdrawApi);
  }

  get pds() {
    return this.#lazyNew(PdsApi);
  }

  get codingImage() {
    return this.#lazyNew(CodingImageApi);
  }

  get codingImageCate() {
    return this.#lazyNew(CodingImageCateApi);
  }

  get codingImageZone() {
    return this.#lazyNew(CodingImageZoneApi);
  }

  get codingSound() {
    return this.#lazyNew(CodingSoundApi);
  }

  get codingSoundCate() {
    return this.#lazyNew(CodingSoundCateApi);
  }

  get codingSoundZone() {
    return this.#lazyNew(CodingSoundZoneApi);
  }

  get bbs() {
    return this.#lazyNew(BbsApi);
  }

  get ink() {
    return this.#lazyNew(InkApi);
  }

  get inkPublished() {
    return this.#lazyNew(InkPublishedApi);
  }

  get sysKey() {
    return this.#lazyNew(SysKeyApi);
  }

  get sysKeyManage() {
    return this.#lazyNew(SysKeyManageApi);
  }

  get dtb() {
    return this.#lazyNew(DtbApi);
  }
}
