// 브라우저의 여러 탭에서 동시에 카메라를 사용가능한가? 크롬에서는 가능한 것을 확인함
// 크롬에서 재생하다가 파이어폭스에서 접근하면, NotReadableError발생,
// 크롬에서 탭을 닫았는데도, 파폭에서 동일한 오류가 발생하는데 이때는 USB다시 연결하면 됨(가이드 표시 필요)
// 아마도, 크롬이 탭을 닫았지만, 카메라를 리소스를 닫지 않고 있어서 인듯?
// 반대로 테스트:
// 파폭에서 재생하다가, 크롬에서 접근하면 마찬가지로 , NotReadableError발생
// 파폭에서 접근권한을 해제하고, 크롬에서 재생하면 잘됨 - 정상
// 파폭은 카메라 리소스 해제를 제대로 하고 있음

// navigator.mediaDevices.getUserMedia() error =
// 'NotAllowedError' // 접근권한 에러
// 'NotFoundError' // 조건에 맞는 미디어트랙이 없는 경우 에러가 발생
// 'NotReadableError' // 권한은 있는데, 로우레벨에서 하드웨어 에러 발생
// 'AbortError' // 다른 장치에서 사용중일때(권한을 부여받았고, NotReadableError도 아닌 상황)
// 'OverconstrainedError' // 조건에 맞는 장치가 없음
// 'SecurityError' // userMedia 지원이 Document상에서 비활성됨, userMedia는 지원하지만, 개별 사용자 에이전트는 비활성상태인 경우
// 'TypeError' // 제약조건 오류, 모두 false로 설정한 경우임
// 'Unknown' // 알수 없는 에러

export const BROWER_NOT_SUPPORT = 'Browser not support';

export type UserMediaErrorType =
  | 'NOT_ALLOWED'
  | 'NOT_FOUND'
  | 'NOT_READABLE'
  | 'INUSE_OTHER'
  | 'NO_MATCH'
  | 'DISABLED'
  | 'INVALID_PARAM'
  | 'BROWSER_NOT_SUPPORT'
  | 'UNKNOWN';

export class UserMediaError extends Error {
  code: UserMediaErrorType;
  constructor(code: UserMediaErrorType, message: string) {
    super(message);
    this.name = 'UserMediaError';
    this.code = code;
  }
}

export const toUserMediaError = (err: any): UserMediaError => {
  // console.log('media error', { name: err.name, type: err.type, message: err.message })
  const message: string | undefined =
    typeof err === 'object' ? err.message || err.name || 'error' : String(err);

  function createErr(type: UserMediaErrorType) {
    return new UserMediaError(type, message || '');
  }

  switch (err.name) {
    case 'NotAllowedError':
      return createErr('NOT_ALLOWED');
    case 'NotFoundError':
      return createErr('NOT_FOUND');
    case 'NotReadableError':
      return createErr('NOT_READABLE');
    case 'AbortError':
      return createErr('INUSE_OTHER');
    case 'OverconstrainedError':
      return createErr('NO_MATCH');
    case 'SecurityError':
      return createErr('DISABLED');
    case 'TypeError':
      return createErr('INVALID_PARAM');
  }

  if (err.message === BROWER_NOT_SUPPORT) {
    return createErr('BROWSER_NOT_SUPPORT');
  }

  return createErr('UNKNOWN');
};
