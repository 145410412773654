import {
  type ApiHelper,
  ApiHelperWithData,
  type BaseRequest,
  splitParams,
} from '@ocodelib/api-common';
import type { BookItem, BookPubl, Doc, InkKindKey } from '../ink/ink-types';
import type { UploadedFile } from '../model/index';

/**
 * ink published api
 */
export class InkPublishedApi {
  private withData: ApiHelperWithData;
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  /**
   * 배포된 BOOK 상세 정보 조회
   */
  bookDetails = (
    params: {
      kind: InkKindKey;
    } & (
      | {
          slug: string;
          bookId?: undefined;
        }
      | {
          slug?: undefined;
          bookId: number;
        }
    ) &
      BaseRequest,
  ): Promise<{
    book: BookPubl;
    bookItems: BookItem[];
    attachFiles?: UploadedFile[];
  }> => {
    const { kind, slug, bookId, ...restParams } = params;
    const url = `/p/api/ink-published/${kind}/book/${slug || bookId}`;
    return this.withData.post(url, ...splitParams(restParams));
  };

  /**
   * 배포된 DOC 상세 정보 조회
   */
  docInfo = (
    params: {
      kind: InkKindKey;
      docSn: number;
    } & BaseRequest,
  ): Promise<{
    doc: Doc;
    attachFiles?: UploadedFile[];
  }> => {
    const { kind, ...restParams } = params;
    const url = `/p/api/ink-published/${kind}/doc-info`;
    return this.withData.post(url, ...splitParams(restParams));
  };
}
