import { type ApiHelper } from '@ocodelib/api-common';

type ApiClass<TApi> = new (helper: ApiHelper) => TApi;

function getProp(target: any, prop: string): string | undefined {
  if (prop in target && typeof target[prop] === 'string') {
    return 'f__' + target[prop];
  }
  return undefined;
}

function randomStr(): string {
  return 'f__' + String(Math.random()).substring(2);
}

export function createLazyApiGenerator(owner: { helper: ApiHelper }) {
  const fieldNameStore = new Map<any, string>();

  const getOrCreateFieldName = (key: any): string => {
    let fieldName = fieldNameStore.get(key);
    if (fieldName) {
      return fieldName;
    }

    fieldName = getProp(key, 'name') || getProp(key, 'id');
    if (fieldName) {
      fieldNameStore.set(key, fieldName);
      return fieldName;
    }

    // random field name(no ssr)
    for (let i = 0; i < 100; i++) {
      fieldName = randomStr();
      if (!fieldNameStore.has(fieldName)) {
        fieldNameStore.set(key, fieldName);
        return fieldName;
      }
    }
    throw new Error('create random field name failed');
  };

  return function createLazyApi<TApi>(cls: ApiClass<TApi>): TApi {
    const prop = getOrCreateFieldName(cls);
    if (prop in owner) {
      return (owner as any)[prop] as TApi;
    }
    const instance = new cls(owner.helper);
    Object.defineProperty(owner, prop, {
      enumerable: false,
      configurable: false,
      writable: false,
      value: instance,
    });
    return instance;
  };
}
