import {
  ApiHelperWithData,
  type BaseRequest,
  splitParams,
  type ApiHelper,
} from '@ocodelib/api-common';
import type { P5Project, PagerData, UploadedFile } from '../model/index';

/**
 * @deprecated use ProjectApi
 */
export class P5ProjectApi {
  private withData: ApiHelperWithData;

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  /**
   * 프로젝트 등록
   * 같은 이름의 프로젝트가 존재해도, 다른 프로젝트로 생성됩니다.
   * 중복 오류 없이 항상 생성됩니다.
   */
  create = (
    params: {
      projectName: string;
      projectFileId: string;
      projectThumbFileId: string;
      memo?: string;
    } & BaseRequest,
  ): Promise<{ project: P5Project }> => {
    const url = '/api/code/p5/project/create';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * 프로젝트 업데이트
   */
  update = (
    params: {
      projectId: string;
      projectName: string;
      projectFileId: string;
      projectThumbFileId: string;
      memo?: string;
    } & BaseRequest,
  ): Promise<{ project: P5Project }> => {
    const url = '/api/code/p5/project/update';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * 프로젝트 목록
   */
  list = (
    params: {
      title?: string;
      pageNumber: number;
      rowsPerPage: number;
    } & BaseRequest,
  ): Promise<{ pagerData: PagerData<P5Project> }> => {
    const url = '/api/code/p5/project/list';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * 프로젝트 정보
   */
  info = (
    params: {
      projectId: string;
    } & BaseRequest,
  ): Promise<{ project: P5Project }> => {
    const url = '/p/api/code/p5/project/info';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * 프로젝트 삭제
   */
  delete = async (
    params: {
      projectId: string;
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/api/code/p5/project/delete';
    await this.helper.post(url, ...splitParams(params));
  };

  /**
   * 소스코드 파일 업로드
   * zip 파일로 업로드
   */
  uploadProjectCodeFile = async (
    params: {
      file: Blob;
      fileName: string;
    } & BaseRequest,
  ): Promise<{ file: UploadedFile }> => {
    const url = '/api/code/p5/project/upload-code-file';
    const { ctx, file, fileName } = params;
    const formData = new FormData();
    formData.append('file', file, fileName);
    const response: { file: UploadedFile } = await this.withData.postMultipart(url, formData, {
      ctx,
    });
    return response;
  };

  /**
   * 썸네일 파일 업로드
   */
  uploadProjectThumbFile = async (
    params: {
      file: Blob;
      fileName: string;
    } & BaseRequest,
  ): Promise<{ file: UploadedFile }> => {
    const url = '/api/code/p5/project/upload-thumb-file';
    const { ctx, file, fileName } = params;
    const formData = new FormData();
    formData.append('file', file, fileName);
    const response: { file: UploadedFile } = await this.withData.postMultipart(url, formData, {
      ctx,
    });
    return response;
  };
}
